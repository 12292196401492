<template>
  <div>
    <div class="card">
      <b-tabs v-model="toolbox_tab" align="left" content-class="mt-1 ml-1">
        <b-tab>
          <template #title>
            <feather-icon icon="ActivityIcon"></feather-icon>
            Gestion des tâches
          </template>
          <b-form-group
            label="Afficher :"
            label-class="font-weight-bold"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-2"
              v-model="task_filter"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
            >
              <b-form-radio value="res"
                >les tâches dont je suis responsable</b-form-radio
              >
              <b-form-radio value="sup"
                >Les tâches que je supervise</b-form-radio
              >
              <b-form-radio value="all" :disabled="userInfo.role_id != 1"
                >Toutes les tâches</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="ListIcon"></feather-icon>
            Liste des tâches
          </template>
          <p>Liste de toutes les tâches de l'entreprise.</p></b-tab
        >
        <b-tab title="First">
          <template #title>
            <feather-icon icon="EditIcon"></feather-icon>
            Création spéciale
          </template>
          <p>Créer des tâches communes à plusieurs collaborateurs.</p></b-tab
        >
      </b-tabs>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <p id="flat" style="position: absolute"></p>
      <b-card v-if="toolbox_tab == 0">
        <b-tabs end nav-class="tab-nav-class" v-model="tab">
          <b-tab
            :title="
              capitalize(localeDay(i + 1) + ' ' + (i + 1) + ' ' + activeMonth)
            "
            v-for="(item, i) in tasksBag"
            :key="i"
            :id="'tab' + i"
          >
            <div @contextmenu.prevent="$refs.menu.open">
              <b-table
                :sort-desc.sync="sortDesc"
                @sort-changed="sortingChanged"
                :ref="'sheet' + tab"
                @row-dblclicked="doubleClick"
                @row-clicked="clickRow"
                @row-contextmenu="rowContext"
                selected-variant="primary"
                bordered
                selectable
                select-mode="single"
                :fields="fields"
                :items="filter(tasksBag.get(item[0]))"
                head-variant="dark"
                thead-class="thead-class"
                tbody-class="popo"
                sticky-header
                v-if="tab == i"
                :key="tableId"
                no-sort-reset
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th>
                      <feather-icon
                        class="task-cal"
                        @click="flat.toggle()"
                        icon="CalendarIcon"
                        data-toggle
                      >
                      </feather-icon>
                    </b-th>
                    <b-th colspan="4" variant="secondary">
                      Liste des tâches du mois de {{ activeMonth }}
                      {{ activeYear }}
                      <br />
                      <small>{{ backup_text }}(Ctrl+ S)</small>
                    </b-th>
                    <b-th variant="primary" class="text-center" colspan="2"
                      >{{ checkedCount(tasksBag.get(item[0])) }}/{{
                        checkedCountTotal(tasksBag.get(item[0]))
                      }}
                      {{
                        tasksBag.get(item[0]).length > 1
                          ? "Terminées"
                          : "Terminée"
                      }}</b-th
                    >

                    <b-th
                      variant="danger"
                      class="text-center"
                      title="Synchroniser"
                      @click="syncronized = !syncronized"
                    >
                      <feather-icon
                        class="task-sync"
                        id="tasks-comment"
                        size="20"
                        icon="RefreshCwIcon"
                      ></feather-icon>
                    </b-th>
                  </b-tr>
                </template>

                <template #cell(id)="data">
                  <span>{{ data.index + 1 }}</span>
                </template>

                <template #cell(is_finished)="data" class="task-respo">
                  <b-form-checkbox
                    v-model="data.item[getKey(data.item)]"
                    class="custom-control-primary"
                    :value="1"
                    :indeterminate="
                      ((!data.item.is_closed && data.item.is_finished) ||
                        (data.item.supervisor_id &&
                          data.item.is_finished &&
                          !data.item.is_closed)) == 1
                        ? true
                        : false
                    "
                    :disabled="_getTaskCheckBoxState(userInfo, data.item)"
                    @change="
                      _taskChecked(
                        data.item,
                        data.item.user_id != userInfo.id &&
                          data.item.supervisor_id != userInfo.id
                      )
                    "
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(name)="data">
                  <b-badge
                    v-if="data.item.priority"
                    :variant="data.item.priorityVariant"
                  >
                    {{ data.item.priorityLabel }}
                  </b-badge>
                  <a
                    class="mt-1"
                    :id="'title' + data.index"
                    :style="data.item.is_closed ? line_through : ''"
                  >
                    {{ data.value || "- - - - - -" }}
                  </a>

                  <b-popover
                    placement="bottomright"
                    :target="'title' + data.index"
                    triggers="hover"
                    variant="dark"
                  >
                    <template #title>
                      Titre
                      <feather-icon
                        class="ml-auto close text-white"
                        size="20"
                        icon="XSquareIcon"
                      ></feather-icon>
                    </template>
                    <div>
                      <b-form-textarea
                        placeholder="Saisir le titre"
                        v-model="data.item.name"
                      >
                      </b-form-textarea>
                    </div>
                  </b-popover>
                </template>
                <template #cell(user_id)="data">
                  <a
                    :id="'master' + data.index"
                    :style="data.item.is_closed ? line_through : ''"
                  >
                    {{ getEmployee(data.item.user_id) || "----" }}
                  </a>
                  <b-popover
                    :target="'master' + data.index"
                    title="Choisissez un responsable"
                    triggers="hover"
                  >
                    <div class="popover-div">
                      <a
                        class="ml-1"
                        @click="addCollaborator"
                        v-if="filtered_masters.length == 0"
                        >Ajouter un collaborateur</a
                      >
                      <div class="m-1">
                        <b-form-input
                          v-model="master_search"
                          class="d-block"
                          placeholder="Rechercher"
                        ></b-form-input>
                      </div>
                      <div v-for="(master, l) in filtered_masters" :key="l">
                        <b-link
                          @click="chooseMaster(item[0], data.index, master)"
                          class="d-flex align-items-center"
                        >
                          <feather-icon
                            :icon="
                              data.item.master_id == master.id
                                ? 'CheckSquareIcon'
                                : 'PlusSquareIcon'
                            "
                            size="16"
                          />

                          <span class="ml-1"
                            >{{ master.firstname + " " + master.lastname }}
                          </span>
                        </b-link>
                        <hr />
                      </div>
                    </div>
                  </b-popover>
                </template>

                <template #cell(supervisor_id)="data">
                  <a
                    :id="'supervisor' + data.index"
                    :style="data.item.is_closed ? line_through : ''"
                  >
                    {{ getEmployee(data.item.supervisor_id) || "----" }}
                  </a>
                  <b-popover
                    :target="'supervisor' + data.index"
                    title="Choisissez un superviseur"
                    triggers="hover"
                  >
                    <div class="popover-div">
                      <a
                        class="ml-1"
                        @click="addCollaborator"
                        v-if="filtered_masters.length == 0"
                        >Ajouter un collaborateur</a
                      >
                      <div class="m-1">
                        <b-form-input
                          v-model="master_search"
                          class="d-block"
                          placeholder="Rechercher"
                        ></b-form-input>
                      </div>
                      <div v-for="(user, l) in supervisors" :key="l">
                        <b-link
                          @click="chooseSupervisor(item[0], data.index, user)"
                          class="d-flex align-items-center"
                        >
                          <feather-icon
                            :icon="
                              data.item.supervisor_id == user.id
                                ? 'CheckSquareIcon'
                                : 'PlusSquareIcon'
                            "
                            size="16"
                          />

                          <span class="ml-1"
                            >{{ user.firstname + " " + user.lastname }}
                          </span>
                        </b-link>
                        <hr />
                      </div>
                    </div>
                  </b-popover>
                </template>

                <template #cell(end_date)="data">
                  <div
                    class="d-flex align-items-center"
                    :style="data.item.is_closed ? line_through : ''"
                  >
                    <flat-pickr
                      :config="config"
                      v-model="data.item.end_date"
                      class="form-control"
                    />
                  </div>
                </template>

                <template #cell(project)="data">
                  <a
                    :id="'project' + data.index"
                    class="d-flex align-items-center"
                    :style="data.item.is_closed ? line_through : ''"
                  >
                    {{
                      data.item.project_id
                        ? getProject(data.item.project_id)
                        : "- - - - - "
                    }}
                  </a>

                  <b-popover
                    :target="'project' + data.index"
                    title="Choisissez un projet"
                    triggers="hover"
                  >
                    <div class="popover-div">
                      <div class="m-1">
                        <b-form-input
                          v-model="project_search"
                          class="d-block"
                          placeholder="Rechercher"
                        ></b-form-input>
                      </div>
                      <div v-for="(project, k) in filtered_projects" :key="k">
                        <b-link
                          @click="chooseProject(item[0], data.index, project)"
                          class="d-flex align-items-center"
                        >
                          <feather-icon
                            :icon="
                              data.item.project != null &&
                              data.item.project.name == project.name
                                ? 'CheckSquareIcon'
                                : 'PlusSquareIcon'
                            "
                            size="16"
                          />

                          <span class="ml-1">{{ project.name }} </span>
                        </b-link>
                        <hr />
                      </div></div
                  ></b-popover>
                </template>
                <template #cell(customer)="data">
                  <a
                    :id="'customer' + data.index"
                    class="d-flex align-items-center"
                    :style="data.item.is_closed ? line_through : ''"
                  >
                    {{ getCustomer(data.item.customer_id) || "-----" }}
                  </a>

                  <b-popover
                    :target="'customer' + data.index"
                    title="Choisisez un client"
                    triggers="hover"
                    v-if="data.item.project == null"
                  >
                    <div class="popover-div">
                      <div class="m-1">
                        <b-form-input
                          v-model="customer_search"
                          class="d-block"
                          placeholder="Rechercher"
                        ></b-form-input>
                      </div>
                      <div v-for="(customer, l) in filtered_customers" :key="l">
                        <b-link
                          @click="chooseCustomer(item[0], data.index, customer)"
                          class="d-flex align-items-center"
                        >
                          <feather-icon
                            :icon="
                              data.item.customer_id != null &&
                              data.item.customer_id == customer.id
                                ? 'CheckSquareIcon'
                                : 'PlusSquareIcon'
                            "
                            size="16"
                          />

                          <span class="ml-1">{{ customer.name }} </span>
                        </b-link>
                        <hr />
                      </div></div
                  ></b-popover>
                </template>
              </b-table>
            </div>
          </b-tab>
        </b-tabs>

        <b-row>
          <b-col cols="auto">Légende :</b-col>
          <b-col
            ><div class="legende-box-danger"></div>
            <p class="ml-3">Tâche en rétard</p></b-col
          >
        </b-row>
      </b-card>
      <b-card v-if="toolbox_tab == 1">
        <all-enterprise-tasks />
      </b-card>
      <b-card v-if="toolbox_tab == 2">
        <h4>Ajouter une tâche commune</h4>
        <validation-observer ref="common_task_form">
          <b-row>
            <b-col md="6">
              <validation-provider
                rules="required"
                name="nom de la tâche"
                #default="{ errors }"
              >
                <b-textarea
                  style="height: 148px"
                  v-model="common_task.name"
                  placeholder="Nom de la tâche"
                >
                </b-textarea>
                <small class="help-text text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-row>
                <b-col md="12">
                  <ky-input
                    :rules="'required'"
                    v-model="common_task.start_date"
                    type="date"
                    placeholder="Date de début"
                  />
                </b-col>
                <b-col md="12">
                  <ky-input
                    :rules="'required'"
                    v-model="common_task.end_date"
                    type="date"
                    placeholder="Date de fin"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col>
              <div class="d-block ml-1">
                <h4>
                  Les collaborateurs concernés ({{ common_task.users.length }})
                </h4>

                <multiselect
                  v-model="common_task.users"
                  tag-placeholder="Sélectionner un collaborateur"
                  placeholder="Rechercher un collaborateur"
                  deselectLabel="Appuyez sur Entrée pour supprimer"
                  selectLabel="Appuyez sur Entrée pour sélectionner"
                  selectedLabel="Sélectionné"
                  label="firstname"
                  track-by="id"
                  :options="masters"
                  :multiple="true"
                  :taggable="true"
                  :custom-label="userName"
                  @remove="removeSelectedUser"
                ></multiselect>

                <small v-if="!state" class="mb-1 help-text text-danger"
                  >Sélectionnez au moins un collaborateur</small
                >
                <b-form-checkbox v-model="checkall"
                  >Tout sélectionner</b-form-checkbox
                >
                <br />

                <b-button @click="_saveCommonTask()">Enregistrer</b-button>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>

      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Chargement...</p>
        </div>
      </template>
    </b-overlay>
    <vue-context ref="menu">
      <li>
        <b-link @click="addLine" class="d-flex align-items-center">
          <feather-icon icon="PlusSquareIcon" size="16" />
          <span class="ml-75">Ajouter une ligne en dessous </span>
        </b-link>
        <hr />
      </li>
      <li v-if="selectedTask && canShowActivityMenu">
        <b-link
          @click="$router.push('/task/' + selectedTask.id + '/changelogs')"
          class="d-flex align-items-center"
        >
          <feather-icon icon="AnchorIcon" size="16" />
          <span class="ml-75">Accéder aux activités </span>
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
        <hr />
      </li>
      <li v-if="selectedTask">
        <b-link @click="removeLine" class="d-flex align-items-center">
          <feather-icon icon="DeleteIcon" size="16" />
          <span class="ml-75"
            >Supprimer <b>{{ selectedTask.title }}</b></span
          >
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
        <hr />
      </li>
      <!-- <li v-if="selectedTask">
        <b-link
          @click="goToTask(selectedTask.id)"
          class="d-flex align-items-center"
        >
          <feather-icon icon="EyeIcon" size="16" />
          <span class="ml-75">Voir les sous-tâches</span>
        </b-link>
        <hr />
      </li> -->

      <li v-if="selectedTask && selectedTask.project">
        <b-link @click="detachProject()" class="d-flex align-items-center">
          <feather-icon icon="GitMergeIcon" size="16" />
          <span class="ml-75"
            >Détacher du projet <b>{{ selectedTask.project.name }}</b></span
          >
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
        <hr />
      </li>
      <li v-if="isCustomerDetachable() && selectedTask.customer">
        <b-link @click="detachCustomer()" class="d-flex align-items-center">
          <feather-icon icon="GitMergeIcon" size="16" />
          <span class="ml-75"
            >Détacher du client <b>{{ selectedTask.customer.name }}</b></span
          >
          <!-- After put, supprimer la ligne sélectionnée -->
        </b-link>
        <hr />
      </li>

      <li v-if="selectedTask">
        <b-link class="d-flex align-items-center" v-b-modal.modalPriority>
          <feather-icon icon="ListIcon" size="16" />
          <span class="ml-75">Définir la priorité </span>
        </b-link>
        <hr />

        <b-modal id="modalPriority" title="Définir une priorité" hide-footer>
          <div class="row mb-2">
            <b-link
              @click="_setPriority(1)"
              class="col-auto d-flex align-items-center ml-2"
              style="border: 1px solid gray; padding: 2px"
            >
              <div class="priority-box-danger"></div>
              <span class="ml-75 mr-75">Très Urgent</span>
            </b-link>
            <b-link
              @click="_setPriority(2)"
              class="col-auto d-flex align-items-center ml-2"
              style="border: 1px solid gray; padding: 2px"
            >
              <div class="priority-box-warning"></div>
              <span class="ml-75 mr-75">Urgent</span>
            </b-link>
            <b-link
              @click="_setPriority(3)"
              class="col-auto d-flex align-items-center ml-2"
              style="border: 1px solid gray; padding: 2px"
            >
              <div class="priority-box-primary"></div>
              <span class="ml-75 mr-75">Non Urgent</span>
            </b-link>
          </div>
        </b-modal>
      </li>
    </vue-context>
  </div>
</template>

<script>
import AppTour from "@/views/AppTour.vue";
import VueContext from "vue-context";
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import vSelect from "vue-select";
import moment from "moment";
import utils from "@/scripts/utils";
import { mapActions, mapState } from "vuex";
import AllEnterpriseTasks from "@/views/shared/tasks/AllEnterpriseTasks.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

import monthSelect from "flatpickr/dist/plugins/monthSelect/index";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    AllEnterpriseTasks,
    VueContext,
    flatPickr,
    FeatherIcon,
    vSelect,
    KyInput,
    Multiselect,
    AppTour,
  },
  data() {
    return {
      common_task: {
        users: [],
      },

      sortDesc: false,

      userInfo: JSON.parse(localStorage.userInfo),

      checkall: false,
      userIsRemovedFromList: false,

      task_filter: localStorage.getItem("task_query") || "res",

      common_task_default: {
        ids: [],
        name: "",
        end_date: "",
        start_date: "",
      },

      loading: true,
      backup_text: "Sauvegardé!",
      toolbox_tab: 0,

      localBackup: new Map(
        JSON.parse(localStorage.getItem("tasks_bag_backup"))
      ),

      fields: [
        {
          key: "id",
          label: "N°",
          thStyle: { width: "1rem" },
          /*  tdClass: (value, key, item) => {
            switch (item.priority) {
              case 1:
                return "index-td-red";
              case 2:
                return "index-td-yellow";
              case 3:
                return "index-td-green";
              default:
                return "";
            }
          }, */

          tdClass: "index-td-red",
        },

        {
          key: "is_finished",
          label: "✔",
          thStyle: { width: "1px" },
        },

        {
          key: "name",
          label: "Tâches",
          sortable: true,
          thClass: "table-th",
        },
        {
          key: "user_id",
          label: "Responsable",
          thStyle: { width: 0 },
          thClass: "table-th-masters",
          sortable: true,
        },
        {
          key: "supervisor_id",
          label: "Superviseur",
          thStyle: { width: 0 },
          sortable: true,
        },
        {
          key: "end_date",
          label: "Date de fin",
          thStyle: { width: "16rem" },
        },

        {
          key: "project",
          label: "Projet",
        },
        {
          key: "customer",
          label: "Client",
        },
      ],

      line_through: "text-decoration:line-through",
      selectedIndex: -1,
      selectedTask: null,
      index: -1,

      config: {
        altFormat: "J F Y",
        locale: French,
        altInput: true,
        dateFormat: "Y-m-d",
      },

      config2: {
        altFormat: "J F Y",
        locale: French,
        dateFormat: "Y-m-d",

        onChange: function (selectedDates, dateStr, instance) {
          this.tab = 10;
        },
        plugins: [
          new monthSelect({
            shorthand: true,
            dateFormat: "M Y",
            theme: "dark",
          }),
        ],
      },

      filtered_masters: [],
      filtered_projects: [],
      filtered_customers: [],

      master_search: "",
      project_search: "",
      customer_search: "",

      add_comment: false,

      flat: null,

      tab: 0,
      syncronized: false,
      check_all_master: false,
      activeTabTasks: null,

      popoverShow: false,
      tableId: 0,
    };
  },

  computed: {
    canShowActivityMenu() {
      var regx = /\w+|-/;
      return regx.test(this.selectedTask.name) == true;
    },
    showVueTour() {
      this.thisVueTour = localStorage.getItem("vueTour");
    },

    ...mapState("tasks", ["tasksBag", "changeOccured", "backuped"]),
    ...mapState("enterprise", ["employees", "clients"]),
    ...mapState("projects", ["projects"]),

    user_ids() {
      return this.common_task.users?.map((item) => item.id);
    },
    state() {
      return this.user_ids?.length > 0;
    },

    masters() {
      return this.employees;
    },

    supervisors() {
      return this.filtered_masters.filter((item) => item.is_supervisor == true);
    },

    daysCount() {
      if (this.flat) {
        return moment(
          this.flat.currentYear + "-" + (this.flat.currentMonth + 1)
        ).daysInMonth();
      } else {
        return 1;
      }
    },

    activeMonth() {
      if (this.flat) {
        return moment(
          this.flat.currentYear + "-" + (this.flat.currentMonth + 1)
        )
          .locale("fr")
          .format("MMMM");
      }
    },

    activeYear() {
      if (this.flat) {
        return this.flat.currentYear;
      }
    },

    selectedKey() {
      if (this.flat) {
        return (
          this.flat.currentYear +
          "-" +
          (this.flat.currentMonth + 1) +
          "-" +
          (this.tab + 1)
        );
      }
    },
  },

  methods: {
    getKey(task) {
      if (
        task.supervisor_id == this.userInfo.id ||
        (task.is_finished == 1 && this.userInfo.id == 19) ||
        (task.is_finished == 1 && this.userInfo.id == 5)
      )
        return "is_closed";
      if (task.supervisor_id == null) return "is_closed";
      if (task.user_id == this.userInfo.id) return "is_finished";
    },

    _taskChecked(task, params) {
      var regex = /^[1-9]+[0-9]*$/;
      if (regex.test(task.id))
        this.taskChecked({ task: task, userInfo: this.userInfo }).then((r) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opération éffectuée.",
              icon: "EditIcon",
              variant: "success",
            },
          });
        });
    },

    _getTaskCheckBoxState(user, task) {
      if (task.is_finished == 1 && user.id == 5) return false;
      if (task.is_finished == 1 && user.id == 19) return false;
      if (task.user_id != user.id && task.supervisor_id != user.id) return true;
      if (task.user_id == user.id && task.supervisor_id == null) return false;
      if (task.supervisor_id == user.id && task.user_id == user.id)
        return false;
      if (task.supervisor_id == user.id && task.is_finished == 0) return true;
      if (task.user_id == user.id && task.is_closed == 1) return true;
    },

    isIndeterminate(task) {
      return parseInt(task.is_finished) == 1 && parseInt(task.is_closed) == 0;
    },
    addTag(tag) {},
    userName({ firstname, lastname }) {
      return firstname + " " + lastname;
    },
    sortingChanged(ctx) {
      // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
      // ctx.sortDesc ==> true if sorting descending, false otherwise
    },
    ...utils,
    ...mapActions("tasks", [
      "tabulate",
      "compute",
      "fill",
      "remove",
      "selectAllMaster",
      "selectProject",
      "selectCustomer",
      "setPriority",
      "getTasks",
      "backup",
      "sync",
      "selectMaster",
      "selectSupervisor",

      "saveCommonTask",
      "postFilter",
      "taskChecked",
    ]),
    ...mapActions("enterprise", ["getEmployees", "fetchCustomers"]),
    ...mapActions("projects", ["getAllProjects"]),

    addCollaborator() {
      this.$router.push({ path: "/admin/users", query: { from: "project" } });
    },

    filter(array) {
      var regex = /^[1-9]+[0-9]*$/;
      var result = array.filter((task) => {
        switch (this.task_filter) {
          case "res":
            if (
              regex.test(task.id) == true &&
              task.user_id == this.userInfo.id
            ) {
              return true;
            }
            if (regex.test(task.id) == false) {
              return true;
            }

            break;
          case "sup":
            if (
              regex.test(task.id) == true &&
              task.supervisor_id == this.userInfo.id
            ) {
              return true;
            }
            if (regex.test(task.id) == false) {
              return true;
            }
            break;
          case "all":
            return true;
        }
      });

      this.postFilter({ date: this.selectedKey, data: result });
      return result;
    },

    async _saveCommonTask() {
      const result = await this.$refs.common_task_form.validate();
      if (!result || !this.state) return;

      this.loading = true;
      var data = new FormData();
      this.common_task.ids = this.user_ids;
      data.append("common_task", JSON.stringify(this.common_task));
      this.saveCommonTask(data)
        .then((r) => {
          this.loading = false;
          this.$bvToast.toast("Succès !", {
            variant: "primary",
            solid: true,
            title: "Message",
          });

          this.common_task = this.common_task_default;
          this.common_task.users = [];
          this.checkall = false;

          this.$nextTick().then((r) => {
            this.$refs.common_task_form.reset();
          });
        })
        .catch((e) => {
          this.loading = false;
          this.$bvToast.toast("Echec !", {
            variant: "danger",
            solid: true,
            title: "Message",
          });
        });
    },

    onTableLoad() {
      let th = document.getElementsByClassName("table-th-masters")[0];

      var p = document.createElement("p");
      p.nodeValue = "Bonjour";
      th.appendChild(p);
      this.forceUpdate();
    },

    getProject(id) {
      var val = this.projects.find((item) => item.id == id);
      return val?.name;
    },

    getEmployee(id) {
      var val = this.employees.find((item) => item.id == id);

      return val ? val.firstname + " " + val.lastname : null;
    },

    getCustomer(id) {
      var val = this.clients.find((item) => item.id == id);

      return val ? val.name : null;
    },
    checkedCount(array) {
      return array?.filter((item) => item.is_finished == true).length;
    },

    checkedCountTotal(array) {
      var regx = /\w+|-/;
      return array?.filter((item) => regx.test(item.name) == true).length;
    },

    isCustomerDetachable() {
      if (!this.selectedTask) return false;
      if (!this.selectedTask.project) return true;
      if (this.selectedTask.project && this.selectedTask.project.customer)
        return false;
    },

    checkedCount(array) {
      return array.filter((item) => item.checked == true).length;
    },

    doubleClick(item, index) {
      this.selectedIndex = index;
      this[`$refs.sheet${this.tab}.clearSelected()`];
    },

    clickRow(item, index) {
      //this.openCtx(item);
      this[`$refs.sheet${this.tab}.selectRow(${index})`];
    },

    openCtx(item, other) {
      this.$refs.menu.open();
    },

    rowContext(item, index) {
      this.selectedTask = item;
      this.selectedIndex = index;
    },

    addLine() {
      const task = {
        checked: false,
        id: 1,
        title: "",
        master: "",
      };

      this.fill(this.selectedKey);

      this.forceUpdate();
      /* subtil */

      // this.tab--
    },

    forceUpdate() {
      this.tableId++;
    },

    removeLine() {
      this.remove({ key: this.selectedKey, index: this.selectedIndex })
        .then((r) => {
          this.forceUpdate();
          this.$bvToast.toast("Une donnée supprimée.", {
            variant: "primary",
            solid: true,
            title: "Message",
          });
        })
        .catch((e) => {
          this.$bvToast.toast("Echec de suppression", {
            variant: "danger",
            solid: true,
            title: "Erreur",
          });
        });
    },

    chooseMaster(item, index, master) {
      this.selectMaster({ key: item, index: index, master: master });
    },
    chooseSupervisor(item, index, supervisor) {
      this.selectSupervisor({
        key: item,
        index: index,
        supervisor: supervisor,
      });
    },

    chooseProject(item, index, project) {
      this.selectProject({ key: item, index: index, project: project });
      this.forceUpdate();
    },

    detachProject() {
      this.chooseProject(this.selectedKey, this.selectedIndex, null);
    },

    detachCustomer() {
      this.chooseCustomer(this.selectedKey, this.selectedIndex, null);
    },

    _setPriority(num) {
      this.setPriority({
        key: this.selectedKey,
        index: this.selectedIndex,
        priority: num,
      });
      this.forceUpdate();
    },

    chooseCustomer(item, index, customer) {
      this.selectCustomer({ key: item, index: index, customer: customer });
      this.forceUpdate();
    },

    taskFocus(item, index) {
      //
      this.selectedIndex = index;

      this.activeTabTasks = this.tasksBag.get(item);
    },

    who(masterArray) {
      if (masterArray) {
        if (
          masterArray.length > 1 &&
          masterArray.length != this.masters.length
        ) {
          return (
            masterArray[0] +
            ` et ${masterArray.length - 1} ${
              masterArray.length - 1 > 1 ? " autres" : " autre"
            }`
          );
        } else if (masterArray.length == 1) {
          return masterArray[0];
        } else if (masterArray.length == this.masters.length) {
          return "Tout le monde";
        } else {
          return "- - - - - -";
        }
      }
    },

    localeDay(num) {
      if (this.flat) {
        return moment(
          this.flat.currentYear + "-" + (this.flat.currentMonth + 1) + "-" + num
        )
          .locale("fr")
          .format("ddd");
      }
    },

    goToTask(id) {
      this.$router.push("/task/" + id);
    },

    removeSelectedUser(val) {
      if (val) {
        this.userIsRemovedFromList = true;
        this.checkall = false;
      }
    },
  },

  watch: {
    task_filter(val) {
      localStorage.task_query = val;

      this.loading = true;

      this.getTasks(this.selectedKey).then((r) => {
        this.loading = false;
        this.sortDesc = true;
        this.forceUpdate();
      });
    },

    checkall(val) {
      if (val == true) {
        this.common_task.users = this.masters;
        this.userIsRemovedFromList = false;
      } else {
        if (!this.userIsRemovedFromList) this.common_task.users = [];
      }
    },

    backuped() {
      this.$bvToast.toast("Enregistré!", {
        variant: "primary",
        solid: true,
        title: "Message",
      });
      this.localBackup = new Map(
        JSON.parse(localStorage.getItem("tasks_bag_backup"))
      );
    },
    changeOccured() {
      this.forceUpdate();
    },
    master_search(val) {
      this.filtered_masters = this.masters.filter(
        (item) =>
          item.firstname.toLowerCase().includes(val.toLowerCase()) ||
          item.lastname.toLowerCase().includes(val.toLowerCase())
      );
    },

    project_search(val) {
      this.filtered_projects = this.projects.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );
    },

    customer_search(val) {
      this.filtered_customers = this.clients.filter((item) =>
        item.name.toLowerCase().includes(val.toLowerCase())
      );
    },

    syncronized() {
      this.backup();
      this.loading = true;
      this.sync(this.selectedKey).then((r) => {
        this.loading = false;
        this.$bvToast.toast("Synchronisation effectuée", {
          variant: "primary",
          solid: true,
          title: "Message",
        });
        //
        this.localBackup = new Map(
          JSON.parse(localStorage.getItem("tasks_bag_backup"))
        );
      }).catch(err => {
        this.loading = false;
        this.$bvToast.toast("Synchronisation echouée", {
          variant: "danger",
          solid: true,
          title: "Message",
        });
      });
    },

    activeMonth() {
      this.localBackup = new Map(
        JSON.parse(localStorage.getItem("tasks_bag_backup"))
      );

      this.compute(
        (this.flat?.currentYear || new Date().getFullYear()) +
          "-" +
          (this.flat?.currentMonth + 1 || new Date().getMonth())
      ).then((d) => {
        this.getTasks(this.selectedKey).then((r) => {
          this.forceUpdate();
        });
      });
    },

    activeYear() {
      this.compute(
        (this.flat?.currentYear || new Date().getFullYear()) +
          "-" +
          (this.flat?.currentMonth + 1 || new Date().getMonth())
      ).then((d) => {});
    },

    tab() {
      if (this.selectedKey) {
        this.getTasks(this.selectedKey).then((r) => {
          this.forceUpdate();
        });
      }
    },
  },
  created() {
    localStorage.task_query = "res";

    window.addEventListener("beforeunload", function (e) {
      if (this.localBackup.size != 0) return;
      var confirmationMessage =
        "It looks like you have been editing something. " +
        "If you leave before saving, your changes will be lost.";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });
  },

  mounted() {
    this.compute(
      (this.flat?.currentYear || new Date().getFullYear()) +
        "-" +
        (this.flat?.currentMonth + 1 || new Date().getMonth())
    ).then((d) => {});

    setTimeout(() => {
      this.flat = flatpickr("#flat", this.config2);
      this.tab = Number(moment(new Date()).format("D")) - 1;
      this.loading = false;
      this.onTableLoad();
      this.getTasks(this.selectedKey).then((r) => {
        this.forceUpdate();
      });
    }, 1000);

    this.getEmployees().then((r) => {
      this.filtered_masters = this.masters;
    });

    this.fetchCustomers().then((r) => {
      this.filtered_customers = this.clients;
    });

    this.getAllProjects().then((r) => {
      this.filtered_projects = this.projects;
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/priority-box.scss";

%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #f8f4f4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: silver;
  }
}

::v-deep .tab-nav-class {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 10%);
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: scroll;
  white-space: nowrap;
  // @extend %scrollbar;
}

.b-table-sticky-header {
  max-height: calc(100vh - 235px) !important;
}

// ::v-deep .card-body {
//   height: calc(100vh - 95px);
//   padding: 1.5rem;
//   overflow-y: hidden;
//   overflow-x: hidden;
// }

.popo {
  height: 370px;
  overflow-y: scroll;
}

.popover-div {
  max-height: calc(100vh / 4);
  overflow-y: scroll;
  @extend %scrollbar;
}

.table-responsive {
  @extend %scrollbar;
}

.flat-form-control {
  border: none;
  cursor: pointer;
}

.task-title {
  max-height: 40px;
  overflow-y: auto;
}

::v-deep.index-td-red {
  border-left: 10px solid red !important;
}

.index-td-green {
  border-left: 10px solid $success !important;
}

.legende-box-danger {
  background: $danger;
  @extend %legende-box;
}
%legende-box {
  height: 20px;
  width: 20px;

  margin-bottom: 10px;
  position: absolute;
}

::v-deep .index-td-yellow {
  border-left: 10px solid $warning !important;
}
</style>
