<template>
  <b-card>
    <div class="justify-content-between flex-wrap pb-2">
      <!-- filter -->
      <b-row>
        <b-col md="auto">
          <b-button class="" size="sm" @click="resetAll">
            Réinitialiser
          </b-button>
        </b-col>
        <b-col>
          <validation-observer ref="searchForm">
            <b-form>
              <b-form-group class="mb-0 ml-auto">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="search"
                    type="text"
                    placeholder="Rechercher par nom de projet..."
                  />
                  <b-input-group-append>
                    <b-button :disabled="!search" @click="tasksFilter(search)">
                      Rechercher
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-form>
        <b-row>
          <b-col>
            <b-form-group>
              <label for="">Responsables :</label>
              <multiselect
                v-model="collaboratorMultiSelect"
                placeholder="Filtrer par responsable"
                :options="masters"
                :custom-label="userName"
                :value="collaboratorMultiSelect"
                selectLabel=""
                deselectLabel=""
                :showNoResults="false"
              >
                span(slot="noResult"). Oops! No elements found. Consider
                changing the search query. pre.language-json code.
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">Superviseurs :</label>
              <multiselect
                v-model="supervisorMultiSelect"
                placeholder="Filtrer par superviseur"
                :options="supervisors"
                :custom-label="userName"
                :value="supervisorMultiSelect"
                selectLabel=""
                deselectLabel=""
                :showNoResults="false"
              ></multiselect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">Date de début du projet :</label>
              <b-input type="date" v-model="filterData.startDate"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label for="">Date de fin du projet :</label>
              <b-input type="date" v-model="filterData.endDate"></b-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="">Priorité :</label>
              <b-select v-model="filterData.priority">
                <b-select-option :value="null"
                  >Filtrer par priorité</b-select-option
                >
                <b-select-option value="no-urgent">Non urgente</b-select-option>
                <b-select-option value="urgent">Urgente</b-select-option>
                <b-select-option value="very-urgent"
                  >Très urgente</b-select-option
                >
              </b-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="">Afficher :</label>
              <b-select v-model="filterData.whoTasks">
                <b-select-option value="all">Toutes les tâches</b-select-option>
                <b-select-option value="is_delayed"
                  >Tâches en retard</b-select-option
                >
                <b-select-option value="in_progress"
                  >Tâches en cours</b-select-option
                >
                <b-select-option value="is_closed"
                  >Tâches achevées</b-select-option
                >
              </b-select>
            </b-form-group>
          </b-col>
        </b-row>
        Afficher :
        <b-row class="mt-1 mb-2">
          <b-col md="auto">
            <b-form-radio v-model="filterData.whoTasks" value="master_tasks"
              >les tâches dont je suis responsable</b-form-radio
            >
          </b-col>
          <b-col>
            <b-form-radio v-model="filterData.whoTasks" value="supervisor_tasks"
              >les tâches dont je suis superviseur</b-form-radio
            >
          </b-col>
          <b-col class="text-right">
            <b-button
              variant="outline-primary"
              :disabled="exportIsProgress"
              size="sm"
              class="mr-1"
              @click="exportToExcel"
            >
              <b-spinner v-if="exportIsProgress" small />
              <feather-icon v-else icon="FileTextIcon" />
              Exporter en excel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <b-table
      :key="allTasksTable"
      selected-variant="primary"
      bordered
      head-variant="dark"
      striped
      empty-text="Aucune tâche trouvée"
      show-empty
      responsive
      class="position-relative"
      :items="allTasksData"
      :fields="fields"
    >
      <template #cell(customer)="data">
        {{ data.item.customer != null ? data.item.customer.name : "..." }}
        <br />
        <b-badge
          :variant="data.item.billable == true ? 'primary' : 'warning'"
          class="mt-1"
        >
          {{ data.item.billable == true ? "Facturable" : "Non facturable" }}
        </b-badge>
      </template>
      <template #cell(is_finished)="data" class="task-respo">
        <b-form-checkbox
          :disabled="_getTaskCheckBoxState(userInfo, data.item)"
          v-model="data.item[getKey(data.item)]"
          :indeterminate="
            ((!data.item.is_closed && data.item.is_finished) ||
              (data.item.supervisor_id &&
                data.item.is_finished &&
                !data.item.is_closed)) == 1
              ? true
              : false
          "
          class="custom-control-primary"
          :value="1"
          @change="_taskChecked(data.item)"
        >
        </b-form-checkbox>
      </template>

      <template #cell(name)="data">
        <div>
          <a :id="'task' + data.item.id">{{ data.item.name }}</a>
          <b-popover
            :title="data.item.name"
            :content="data.item.name"
            placement="top"
            triggers="hover"
            variant="dark"
            :target="'task' + data.item.id"
          >
            <div class="row">
              <b-row>
                <b-col cols="auto"
                  ><small class="col-12 text-muted text-truncate mb-0 ml-auto"
                    >Tâche :</small
                  >
                </b-col>
              </b-row>
              <b-badge v-if="data.item.billable == true">Facturable</b-badge>
              <b-badge variant="warning" v-if="data.item.billable == false"
                >Non facturable</b-badge
              >
              <div class="m-1 col-12 ml-auto">
                <b-form-checkbox
                  v-model="data.item.billable"
                  class="custom-control-primary"
                  @change="_markBillable(data.item)"
                  >Facturable
                </b-form-checkbox>
              </div>
            </div>

            <div class="row">
              <small class="">Description</small>
              <div class="col-12">
                <p class="text-muted">{{ data.item.description }}</p>
              </div>
            </div>
          </b-popover>
        </div>
      </template>
    </b-table>

    <div class="">
      <!-- pagination -->
      <b-row class="row row-cols-2">
        <b-col md="3">
          <b-row>
            <b-col>
              <small>Afficher par page</small>
            </b-col>
            <b-col>
              <b-select v-model="thisTableSize">
                <b-select-option value="10">10</b-select-option>
                <b-select-option value="20">20</b-select-option>
                <b-select-option value="30">30</b-select-option>
              </b-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8" class="d-flex flex-row-reverse">
          <div class="">
            <b-pagination
              v-model="currentPage"
              :total-rows="allTasksMeta.total"
              :per-page="allTasksMeta.per_page"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 text-right"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BTooltip,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
    vSelect,
    BTooltip,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    ToastificationContent,
    ValidationObserver,
  },
  data() {
    return {
      exportIsProgress: false,
      showMoreVertical: false,
      currentPage: 1,
      collaboratorMultiSelect: null,
      supervisorMultiSelect: null,

      errors: "",
      task: {},
      thisTableSize: 10,
      filterData: {
        collaboratorId: null,
        supervisorId: null,
        startDate: null,
        endDate: null,
        whoTasks: "all",
        table_size: 10,
        page: 1,
        priority: "null",
      },
      allTasksTable: 0,
      loading: false,
      search: "",

      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "is_finished",
          label: "✔",
          thStyle: { width: "1rem" },
          sortable: true,
        },
        {
          key: "id",
          label: "#ref",
          thStyle: { width: "1rem" },
          sortable: true,
        },
        { key: "name", label: "Nom", sortable: true },
        { key: "customer", label: "Projet Client", sortable: true },
        {
          key: "start_date",
          label: "Date debut",
          sortable: true,
        },
        {
          key: "end_date",
          label: "Date Fin",
          sortable: true,
        },
        {
          key: "master",
          label: "Responsable",
          thStyle: { width: "2rem" },
          sortable: true,
        },
        {
          key: "supervisor",
          label: "Superviseur",
          thStyle: { width: "2rem" },
          sortable: true,
        },
      ],
    };
  },
  watch: {
    thisTableSize() {
      this.filterData.table_size = this.thisTableSize;
      this.myGlobalFilter(this.filterData.table_size);
    },

    currentPage(val) {
      this.filterData.page = val;
      this.myGlobalFilter(this.filterData);
    },
    search() {
      if (this.search == "") {
        this.filterData.whoTasks = "all";
        return this.myGlobalFilter(this.filterData);
      }
    },
    collaboratorMultiSelect(val) {
      if (val) {
        this.filterData.collaboratorId = val.id;
      } else {
        this.filterData.collaboratorId = null;
      }
    },
    supervisorMultiSelect(val) {
      if (val) {
        this.filterData.supervisorId = val.id;
      } else {
        this.filterData.supervisorId = null;
      }
    },
    filterData: {
      deep: true,
      handler() {
        this.filterData.table_size = this.thisTableSize;
        return this.myGlobalFilter(this.filterData);
      },
    },
  },
  computed: {
    masters() {
      return this.employees;
    },
    allTasksData() {
      return this.alltasks.data;
    },
    allTasksMeta() {
      return this.alltasks.meta || {};
    },

    ...mapState("enterprise", ["alltasks", "employees", "supervisors"]),
    ...mapState("tasks", ["tasks"]),
    ...mapState("auth", ["userInfo"]),
  },
  mounted() {
    this.myGlobalFilter(this.filterData);
    this.getEmployees();
    this.fetchAllSupervisors();
  },

  methods: {
    exportToExcel() {
      this.exportIsProgress = true;
      this.$store
        .dispatch("enterprise/exportFilter", this.filterData)
        .then((res) => {
          const anchor = document.createElement("a");
          anchor.href = res.data;
          document.body.appendChild(anchor);
          anchor.click();
          this.exportIsProgress = false;
        })
        .catch((err) => {
          this.exportIsProgress = false;
        })
        .finally(() => {
          this.exportIsProgress = false;
        });
    },

    getKey(task) {
      if (
        task.supervisor_id == this.userInfo.id ||
        (task.is_finished == 1 && this.userInfo.id == 19) ||
        (task.is_finished == 1 && this.userInfo.id == 5)
      )
        return "is_closed";
      if (task.supervisor_id == null) return "is_closed";
      if (task.user_id == this.userInfo.id) return "is_finished";
    },

    getBillable(task) {
      if (task.billable == 1) {
        return true;
      } else {
        return false;
      }
    },
    _taskChecked(task) {
      this.taskChecked({ task: task, userInfo: this.userInfo }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    _getTaskCheckBoxState(user, task) {
      if (task.is_finished == 1 && user.id == 5) return false;
      if (task.is_finished == 1 && user.id == 19) return false;
      if (task.user_id != user.id && task.supervisor_id != user.id) return true;
      if (task.user_id == user.id && task.supervisor_id == null) return false;
      if (task.supervisor_id == user.id && task.user_id == user.id)
        return false;
      if (task.supervisor_id == user.id && task.is_finished == 0) return true;
      if (task.user_id == user.id && task.is_closed == 1) return true;
    },

    _markBillable(task) {
      this.taskBillable({ task: task }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    userName({ firstname, lastname }) {
      return firstname + " " + lastname;
    },
    resetAll() {
      (this.collaboratorMultiSelect = null),
        (this.supervisorMultiSelect = null),
        (this.filterData.collaboratorId = null);
      this.filterData.supervisorId = null;
      this.filterData.startDate = null;
      this.filterData.endDate = null;
      this.filterData.whoTasks = "all";
      this.filterData.priority = null;
    },
    checkTaskClosed(data) {
      if (data.is_closed == 1) {
        data.is_closed = 0;
        this.closedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche relancée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        data.is_closed = 1;
        this.closedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche clôturée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    checkTaskFinished(data) {
      if (data.is_finished == 1) {
        data.is_finished = 0;
        this.finishedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche relancée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        data.is_finished = 1;
        this.finishedTask(this.$formData(data))
          .then((r) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Tâche terminée avec succès",
                icon: "EditIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Vous n'êtes pas autorisé à clôturer cette tâche",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    tasksFilter(data) {
      this.allTasksFilter(data);
    },

    ...mapActions("enterprise", [
      "fetchAllEnterpriseTasks",
      "allTasksFilter",
      "getEmployees",
      "fetchAllSupervisors",
      "myGlobalFilter",
      "exportFilter",
    ]),
    ...mapActions("tasks", [
      "closedTask",
      "finishedTask",
      "taskChecked",
      "taskBillable",
    ]),
  },
};
</script>
